import { JSX, mergeProps, splitProps } from "solid-js";

import createInitial from "../signals/createInitial";

declare namespace Fade {
  type Direction = keyof typeof translationByDirection;

  interface Props {
    class?: string;
    style?: JSX.CSSProperties;
    children: JSX.Element;
    transition?: JSX.CSSProperties["transition"];
    direction?: Direction;
    show?: unknown;
  }
}

const translationByDirection = {
  up: [0, 1],
  right: [1, 0],
  down: [0, -1],
  left: [-1, 0],
} as const;

function Fade(_props: Fade.Props) {
  const [props, rest] = splitProps(mergeProps(_props, { show: true }), [
    "direction",
    "transition",
    "style",
    "show",
  ]);

  const initial = createInitial();

  const show = () => !initial() && props.show;

  return (
    <div
      style={{
        transition: props.transition || "var(--normal-s)",
        opacity: show() ? 1 : 0,
        transform: props.direction
          ? (() => {
              const [x, y] = translationByDirection[props.direction];
              return `translate(${x * 100}%, ${y * 100}%)`;
            })()
          : undefined,
        ...props.style,
      }}
      {...rest}
    />
  );
}

export default Fade;
